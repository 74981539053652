<template>
  <div id="me_shop">
    <oTitle title="我的商城" :more="false"></oTitle>
    <ul class="me_shop-tool">
      <li @click="goRoute('luckRecord')">
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="11.5543"
            width="40"
            height="8"
            rx="4"
            stroke="#666666"
            stroke-width="3"
          />
          <path
            d="M5 19.5543H39V35.5543C39 38.868 36.3137 41.5543 33 41.5543H11C7.68629 41.5543 5 38.868 5 35.5543V19.5543Z"
            stroke="#666666"
            stroke-width="3"
          />
          <path
            d="M21.9997 11.0543C21.1664 8.05429 18.0158 2.3858 11.9997 3.05429C7.5 3.55429 9.83308 9.38762 12.4997 12.0543"
            stroke="#666666"
            stroke-width="3"
          />
          <path
            d="M22.0003 11.0543C22.8336 8.05429 25.9842 2.3858 32.0003 3.05429C36.5 3.55429 34.1669 9.38762 31.5003 12.0543"
            stroke="#666666"
            stroke-width="3"
          />
          <path d="M22 42.0543V12.0543" stroke="#666666" stroke-width="3" />
        </svg>

        <span>中奖记录</span>
      </li>
      <li @click="goRoute('Team2')">
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 41.5L17.7076 32.4393C17.8681 31.9023 17.5541 31.3412 17.0252 31.1554C11.1861 29.1033 7 23.5405 7 17C7 8.71573 13.7157 2 22 2C30.2843 2 37 8.71573 37 17C37 23.3546 33.0485 28.7863 27.4687 30.9719C26.9447 31.1771 26.6509 31.7524 26.8337 32.2846L30 41.5"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M8.09091 41L9.70527 35.627C9.86689 35.0891 9.55113 34.5282 9.02771 34.3245C5.4968 32.9501 3 29.6297 3 25.4937C3 22.4479 4.44207 19.7372 6.68417 18"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M36.0007 41L34.3159 35.6475C34.1443 35.1025 34.4662 34.529 35.0013 34.3286C38.7098 32.9401 41.334 29.5078 41.334 25.4937C41.334 22.4479 39.8232 19.7372 37.4744 18"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M27.8995 22C26.637 23.2372 24.9079 24 23.0005 24C21.0932 24 19.3641 23.2372 18.1016 22"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>

        <span>我的团队</span>
      </li>
      <li @click="goRoute('safeCode')">
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 8.04027C4 6.24347 5.19463 4.66998 6.94211 4.2519C10.6561 3.36335 17.1457 2 22 2C26.8543 2 33.3439 3.36335 37.0579 4.2519C38.8054 4.66998 40 6.24348 40 8.04027V29.7648C40 31.1539 39.2801 32.4433 38.0962 33.1697C33.394 36.0551 27.2374 39.8093 24.0745 41.7365C22.7973 42.5147 21.2027 42.5147 19.9255 41.7365C16.7626 39.8093 10.606 36.0551 5.90381 33.1697C4.71989 32.4433 4 31.1539 4 29.7648V8.04027Z"
            stroke="#666666"
            stroke-width="3"
          />
          <circle
            cx="21.5"
            cy="19.5"
            r="7.5"
            stroke="#666666"
            stroke-width="3"
          />
          <path
            d="M32.4995 28L27.7148 24.0681"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>

        <span>真伪查询</span>
      </li>

      <li @click="getPosters">
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="1.5"
            width="14"
            height="14"
            rx="3"
            stroke="#666666"
            stroke-width="3"
          />
          <rect
            x="2"
            y="28.5"
            width="14"
            height="14"
            rx="3"
            stroke="#666666"
            stroke-width="3"
          />
          <rect
            x="28"
            y="1.5"
            width="14"
            height="14"
            rx="3"
            stroke="#666666"
            stroke-width="3"
          />
          <path
            d="M2 22H42"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M22 2V16.5"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M22 26V37"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M28.5 30.5V42"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M35.5 30.5V41.5"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M41 26.5V41.5"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>

        <span>推广码</span>
      </li>

      <li @click="$router.push({ name: 'goodsTailor' })">
        <svg-icon iconClass="me_onlyProduct"></svg-icon>

        <span>一客一装</span>
      </li>
    </ul>

    <Overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="erweima_wrapper">
          <img
            class="erweima_img"
            :src="haibao_img"
            alt=""
            @click="getPosters"
          />
          <p>长按保存图片-点击海报可切换</p>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import oTitle from "../title/h1.vue";
import { Overlay, Toast } from "vant";
import { post } from "@get/http";
export default {
  data() {
    return {
      haibao_img: "", //当前海报
      show: false, //海报是否展示
      hb_get_thisHB: 0, //当前海报序列号
      hb_data: [], //海报配置
    };
  },
  components: {
    oTitle,
    Overlay,
  },
  methods: {
    goRoute(page, q) {
      this.$router.push({
        name: page,
        query: { ...q },
      });
    },
    async onLoad() {
      // 自启动
      try {
        //获得所有书生海报的配置
        let { data } = await post("/Posters/index", {
          data: {
            page: 1,
          },
        });
        this.hb_data = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPosters() {
      // 获取书生海报
      Toast.loading({
        message: "加载中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      if (this.hb_get_thisHB == this.hb_data.length) {
        this.hb_get_thisHB = 0;
      }
      let { posters_image } = await post("/Member/getPosters", {
        data: {
          posters_config_id: this.hb_data[this.hb_get_thisHB].id,
        },
      });
      this.show = true;
      this.haibao_img = env.SITE_URL + posters_image;
      this.hb_get_thisHB++;
      Toast.clear();
    },
  },
  async created() {
    this.onLoad();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#me_shop {
  width: rem(690);
  margin: 0 auto rem(24);
  border-radius: rem(16);
  background: #fff;
  box-sizing: border-box;
  padding: rem(30) rem(30) rem(42);
}
.me_shop-tool {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin-top: rem(42);
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(46);
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-top: 0;
    }
    svg {
      margin-bottom: rem(18);
      width: rem(44);
      height: rem(44);
    }
    span {
      color: #222222;
      font-size: rem(28);
    }
  }
}

.wrapper {
  position: relative;
}
.erweima_wrapper {
  // margin:  1rem auto 0 ;
  top: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 70%;
  background: #fff;
  position: absolute;
  p {
    text-align: center;
    padding: 0.2rem 0;
  }

  .erweima_img {
    display: block;
    width: 100%;
  }
}
</style>
